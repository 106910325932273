import React from 'react'
import { css } from '../../../styled-system/css'
import { Link, navigate } from 'gatsby'
import { Button } from './Button'
import ResponsiveImage from './ResponsiveImage'
export default function Reservation({
  id,
  image,
}: {
  id: string
  image: string
}) {
  return (
    <div
      className={css({
        width: '22.5%',
        height: '650px',
        overflow: 'hidden',
        position: 'relative',
        margin: 'auto',
        marginBottom: '2rem',
        '@media (max-width: 768px)': {
          width: '85%',
          marginBottom: '2rem',
        },
      })}
    >
      <h2
        className={css({
          position: 'absolute',
          color: 'white',
          borderBottom: 'white 2px solid',
          width: '100%',
          textAlign: 'center',
          fontSize: '50px',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
        })}
      >
        {id}
      </h2>
      <ResponsiveImage
        className={css({
          maxWidth: 'none',
          maxHeight: '100%',
          width: 'auto',
          height: 'auto',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: '-1',
        })}
        relativePath={image}
        alt="test"
      />
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem',
          marginTop: '32.5rem',
          zIndex: '1',
        })}
      >
        <Link to="/photographie/contact">
          <Button variants={{ format: 'normal', visual: 'digit' }}>
            <span
              className={css({
                fontFamily: 'Exo2',
                color: '#000000',
                fontSize: '1rem',
                textAlign: 'center',
              })}
            >
              JE RESERVE
            </span>
          </Button>
        </Link>
      </div>
    </div>
  )
}
